.loader-overlay {
  background-color: rgba(211, 211, 211, 0.5); /* Light gray with 50% opacity */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px); /* Blur effect for modern browsers */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
}

.loader-linear {
  width: 10%;
  height: 4px; /* Height of the linear progress bar */
  background-color: #ffffff; /* Color of the progress bar */
  position: relative;
  overflow: hidden;
}

.loader-linear::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #4caf50; /* Color of the progress */
  position: absolute;
  top: 0;
  left: -100%;
  animation: progress 2s linear infinite; /* Adjust animation duration as needed */
}
.loader-bottom-css {
  margin-top: 20px !important;
}

@keyframes progress {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
