.tab_wpr {
    border-radius: 18px;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
    padding: 40px;
}
.tab_hdg_wpr {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tab_hdg {
    color: #000;
    font-size: 22px;
    font-weight: 700;
    text-transform: capitalize;
}
.prf_details {
    display: flex;
    margin-top: 30px;
    gap: 40px;
}
.prf_img {
    flex: 0 0 130px;
    height: 130px;
}
.prf_img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 50%;
    background-color: #f4f4f4;
}
.prf_description {
    flex-grow: 1;
}
.prf_description h5 {
    color: #1C1B1B;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 30px;
}

.prf_fields {
    display: flex;
    gap: 30px;
}

.prf_tab .prf_fields {
    gap: 160px !important;
}

.prf_label {
    color: #979797;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
}

.prf_text {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.prf_text span {
    width: 22px;
    height: 22px;
    background-color: var(--pink-color);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 6px;
}

/* Profile Edit */


.prf_upload {
    flex: 0 0 170px;
    height: 170px;
    position: relative;
    border-radius: 50%;
    background: #8080801c;
    border: 1px solid #f6f6f6;
}
.bg-upload {
    flex: 0 0 100%;
    border-radius: 15px;
    margin-bottom: 20px;
    background-color: #e4e4e4;
}
.prf_upload input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.prf_upload .preview_img {
    width: 100%;
    height: 100%;
    filter: drop-shadow(2px 4px 6px #80808024);
    border-radius: 50%;
    object-fit: contain;
    display: block;
    background-color: #f6f6f6;
}
.bg-upload .preview_img {
    border-radius: 15px;
    filter: unset;
    object-fit: cover;
}
.prf_upload img:not( .preview_img) {
    position: absolute;
    bottom: 10px;
    right: 0;
}
.bg-upload + .edit_details {
    margin-top: -70px;
}
.edit_details{
    gap: 60px;
}
.edit_fields {
    flex-wrap: wrap;
    row-gap: 50px;
    column-gap: 40px;
    margin-bottom: 80px;
}

.edit_fields .prf_field_Item {
    flex: 0 0 calc(100% / 2 - 20px);
}
.edit_fields  .prf_label {
    color: var(--secondry-color);
    font-size: 16px;
    font-weight: 700;
}
.edit_fields  .prf_text input {
    border-radius: 24px;
    border: 1px solid var(--secondry-color);
    background: #FDFDFD;
    padding: 14px 20px;
    width: 100%;
    font-size: 14px;
    color: #000;
}

.edit_fields .prf_text input::placeholder {
    color: #949494;
}

/* Three item per row */
.edit_fields-3 {
    gap: 30px;
}
.edit_fields-3 .prf_field_Item {
    flex: 0 0 calc(100% / 3 - 20px);
}
.edit_fields-3 .prf_field_Item::placeholder{
    line-height: 28px;
}


/* Contact Tab */
.contact_details .prf_fields{
    gap: 100px;
}

.contact_details .prf_field_Item {
    max-width: 240px;
}

.contact_details .prf_field_Item .prf_text {
    display: flex;
    gap: 6px;
}

.contact_details .prf_field_Item .prf_text span {
    flex: 0 0 22px;
}

.contact_desc textarea {
    border-radius: 24px;
    border: 1px solid var(--secondry-color);
    background: #FDFDFD;
    width: 100%;
    min-height: 120px;
    margin-top: 40px;
    font-size: 16px;
    resize: none;
    padding: 20px
}
.contact_desc textarea::placeholder{
    color:#949494;
}

.delete_account p {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    margin: 10px 0 30px;
}