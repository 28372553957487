.custom-file-input {
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  /* background-color: #3498db; */
  color: black;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
}

.custom-file-input {
  /* background-color: #2980b9; */
  color: black;
}
.image-uploader label {
  /* border-style: dotted; */
  border: 2px dotted #0e81ce;
  /* border-color: #b1bfd0; */
  position: relative;
  padding: 25px 70px;
  /* width: 100%; */
  border-radius: 20px;
}

#fileInput {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}
.image-preview img {
  max-width: 500px;
  max-height: 500px;
  margin-top: 20px;
}

.image-uploader {
  margin-top: 10px;
}

.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.d-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.drop_file {
  font-size: 12px;
  font-weight: 600;
  color: #949494;
}

.drop_brow {
  font-size: 15px;
  font-weight: 700;
  color: #0e81ce;
}

.drop_supp {
  font-size: 15px;
  font-weight: 600;
}
