.todo_list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.task_item {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
    padding: 14px 16px;
}

.task_item_top {
    display: flex;
    align-items: center;
    gap: 10px;
}

.task_item_Icon {
    display: flex;
    align-items: center;
}

.task_item_Icon svg {
    width: 22px;
    height: 22px;
}

.task_item_Icon img{
    width: 24px;
}
.task_item h5 {
    flex-grow: 1;
    color: var(--primary-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.task_item_btns {
    display: flex;
    align-items: center;
    gap: 10px;
}

.task_item_bottom {
    align-items: center;
    gap: 15px;
    transition: all 0.3s ease-in-out;
    overflow-y: hidden;
    height: 0;
    opacity: 0;
}

.task_item_bottom.show {
    display: flex;
    padding-top: 20px;
    opacity: 1;
    height: auto;
}

.due_Date {
    color: #4B4B4B;
    font-size: 18px;
    font-weight: 700;
    margin-top: 10px;
}

/* Stepper */
.stepper_list {
    display: flex;
    padding-top: 10px;
    gap: 20px;
}

.stepper_item {
    max-width: 190px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
    position: relative;
}

.step_name {
    font-size: 12px;
    font-weight: 700;
    color: var(--gray-color);
}

.step_date {
    font-size: 10px;
    color: var(--gray-color);
    display: flex;
    align-items: center;
    gap: 4px;
}

.step_date p {
    line-height: 0 !important;
}

.step_mid {
    display: flex;
    gap: 5px;
    align-items: center;
}

.step_mid img {
    width: 10px;
    height: 10px;
    flex: 0 0 10px;
}

.step_mid span {
    display: flex;
    background: var(--gray-color);
    width: 100%;
    height: 2px;
}

.color_dot {
    width: 8px;
    height: 7px;
    flex: 0 0 8px;
    background: var(--gray-color);
    border-radius: 50%;
}

.stepper_item.submitted .step_name {
    color: var(--secondry-color);
}

.stepper_item.submitted .step_mid span {
    background-color: var(--secondry-color);
}

.stepper_item.submitted .color_dot {
    background-color: var(--secondry-color);
}

.stepper_item.rework .step_name {
    color: var(--yellow-color);
}

.stepper_item.rework .step_mid span {
    background-color: var(--yellow-color);
}

.stepper_item.rework .color_dot {
    background-color: var(--yellow-color);
}

.stepper_item.approved .step_name {
    color: var(--green-color);
}

.stepper_item.approved .step_mid span {
    background-color: var(--green-color);
}

.stepper_item.approved .color_dot {
    background-color: var(--green-color);
}


/* Stepper Verticle */
.stepper_verticle {
    flex-direction: column;
    padding: 0;
    gap: 10px;
}

.stepper_verticle .stepper_item {
    max-width: 100%;
    padding-left: 20px;
    height: 65px;
}

.stepper_verticle .stepper_item:last-child {
    height: unset;
}

.stepper_verticle .step_mid {
    position: absolute;
    left: 0;
    top: 10px;
    height: 100%;
    flex-direction: column;
}

.stepper_verticle .step_mid span {
    width: 1px;
    height: 100%;
    background: transparent;
    border: 1px dashed var(--gray-color);
}

.stepper_verticle .stepper_item:last-child .step_mid span {
    display: none;
}

.cstm_popover_content {
    font-size: 10px;
    color: #000;
    max-width: 260px;
    padding: 5px 8px;
    background: #a5a5a569;
}
.task_ctnt {
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
    color: #6c6c6c;
    word-break: break-word;
}