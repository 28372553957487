@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

/* Reset CSS*/
:root {
  --primary-color: #04103b;
  --secondry-color: #197bbd;
  --yellow-color: #e9a73e;
  --green-color: #377934;
  --pink-color: #e75a7c;
  --white-color: #ffffff;
  --black-color: #000000;
  --base-color: #f4f4f4;
  --gray-color: #a7a7a7;
  /* --font-family: 'Arial', sans-serif; */
  --font-family: "Nunito", sans-serif;
  --font-family2: "Nunito", sans-serif;
}

.op-5 {
  opacity: 0.5;
}

*,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family);
}

.text-center {
  text-align: center !important;
}

.disbled {
  pointer-events: none;
  opacity: 0.5;
}

.empty {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 15px;
}

.wbreak {
  word-break: break-all;
}

.w-100 {
  width: 100% !important;
  flex: 0 0 100% !important;
}

.df-h-center {
  display: flex;
  justify-content: center;
}

.disable {
  filter: grayscale(1);
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.w-auto {
  width: unset !important;
}

.lh-0 {
  line-height: 0;
}

.d-flex {
  display: flex;
}

.gap-10 {
  gap: 10px;
}

.justify-end {
  justify-content: flex-end !important;
}

.align-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-space-between {
  justify-content: space-between !important;
}

body {
  background-color: var(--base-color);
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.pe-none {
  pointer-events: none;
  cursor: no-drop;
}

ul,
li,
ol {
  list-style: none;
}

.prft_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.pr_icn .prft_img {
  object-fit: cover;
  border: 1px solid #a9a9a9;
}

.circle {
  border-radius: 50%;
}

a,
a:hover {
  text-decoration: none;
}

.text {
  font-weight: 400;
  text-transform: capitalize;
  color: #000;
}

.cursor_pointer {
  cursor: pointer;
}

input[type="date"]:after {
  /* content: attr(placeholder) */
}

.sd-container {
  position: relative;
  float: left;
}

.sd {
  border: 1px solid #1cbaa5;
  padding: 5px 10px;
  height: 30px;
  width: 150px;
}

.open-button {
  position: absolute;
  top: 10px;
  right: 3px;
  width: 25px;
  height: 25px;
  background: #fff;
  pointer-events: none;
}

.open-button button {
  border: none;
  background: transparent;
}

input:focus::-webkit-datetime-edit {
  color: #000;
}

input:focus,
input:focus-visible,
input:focus-within,
textarea:focus,
textarea:focus-visible,
textarea:focus-within,
select:focus,
select:focus-visible,
select:focus-within {
  /* border: 0; */
  outline: 0;
  box-shadow: none;
}

.cstm_Ctnr {
  padding: 0 50px;
}

.white_bx {
  display: flex;
  padding: 40px 50px;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
}

.min-vh {
  min-height: 100vh;
}

.sec-padding {
  padding: 40px 0;
}

.sec-padding-top {
  padding-top: 40px;
}

.sec-padding-bottom {
  padding-bottom: 40px;
}

.error_text {
  color: #ad1d1d;
  font-size: 12px;
  margin-top: 4px;
}

button:disabled {
  filter: grayscale(1);
  opacity: 0.8;
  cursor: not-allowed;
}

/* Margins */
.m-0 {
  margin: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.me-10 {
  margin-right: 10px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-50 {
  padding: 50px !important;
}

.transition {
  transition: all 0.3s ease-in-out 0.1s;
}

/* Typography */
.fz-14 {
  font-size: 14px;
  line-height: 1.5em;
}

.fz-16 {
  font-size: 16px;
  line-height: 1.5em;
}

.fz-18 {
  font-size: 18px;
  line-height: 1.5em;
}

.fz-20 {
  font-size: 20px;
  line-height: 1.5em;
}

.fz-22 {
  font-size: 22px;
  line-height: 1.5em;
}

.fz-24 {
  font-size: 24px;
  line-height: 1.5em;
}

.primary-color {
  color: var(--primary-color) !important;
}

.secondry-color {
  color: var(--secondry-color) !important;
}

.pink-color {
  color: var(--pink-color) !important;
}

.green-color {
  color: var(--green-color) !important;
}

.text-dark label {
  color: #000 !important;
}

.left {
  text-align: left !important;
}

/* Buttons */
.btn_reset {
  border: 0;
  padding: 0;
  cursor: pointer;
  background-color: #0000;
  transition: all 0.3s ease-in-out 0.1s;
}

.download_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: var(--pink-color);
  color: #fff;
  padding: 4px 15px 4px 16px;
  border-radius: 20px;
  font-size: 12px;
  gap: 14px;
  cursor: pointer;
}

.download_btn img {
  max-height: 18px;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
}

.upload_btn img {
  max-width: 30px;
  max-height: 30px;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
}

.theme_btn,
.blue_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: var(--pink-color);
  color: #fff;
  padding: 10px 26px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

.blue_btn {
  background-color: var(--secondry-color);
  padding: 12px 25px;
  border-radius: 35px;
}

.blue_btn:hover {
  background-color: var(--pink-color);
}

a.pink_btn,
.pink_btn {
  display: inline-flex;
  border-radius: 24px;
  border: 1px solid var(--pink-color);
  background: var(--pink-color);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 9px 26px;
  color: #fff !important;
  font-size: 16px;
  transition: all 0.3s ease-in-out 0.1s;
}

.pink_btn:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.pink_btn.sm {
  padding: 6px 24px;
  font-size: 0.8em;
}

.green_btn {
  background-color: var(--green-color);
  border-color: var(--green-color);
}

/* *********** */
/* Sidebar */
li a.active {
  border-left-color: #fff !important;
}

li a.active span:nth-child(2) {
  background: #fff !important;
  color: #000 !important;
}

/* Main Container */
/* Start Main container */
.main_container {
  display: flex;
}

.ctnt_wpr {
  flex: 1;
}

#logout_Btn {
  display: flex;
  align-items: center;
  gap: 8px;
}

#logout_Btn_Menu ul {
  padding: 0;
}

#logout_Btn_Menu .MuiPaper-elevation1 {
  border-radius: 10px;
}

/* cutom Inputs */
.cstm_Input {
  flex-grow: 1;
  border-radius: 25px;
  border: 1px solid var(--secondry-color);
  padding: 12px 18px;
  font-size: 14px;
  color: #000;
}

.cstm_Input::placeholder {
  color: #949494;
}

.cstm_Input:focus {
  border: 1px solid var(--secondry-color);
  box-shadow: none;
  outline: 0;
}

.upload_input {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 15px;
  gap: 10px;
  border: 1px dashed #00000070;
  width: 100%;
  border-radius: 35px;
  font-size: 12px;
  color: #000;
  position: relative;
  z-index: 0;
  transition: all 0.3s ease-in-out 0.1s;
}

.upload_input input {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.upload_input .file_text {
  flex-grow: 1;
}

.file-select {
  color: var(--secondry-color);
  font-size: 9px;
  font-weight: 400;
  text-transform: uppercase;
  border: 1px solid var(--secondry-color);
  display: inline-block;
  padding: 8px 20px;
  border-radius: 30px;
}

/* Privacy pages */
.privacy_layout {
  border-radius: 18px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
  padding: 50px;
  min-height: 80vh;
}

.privacy_heading {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-bottom: 50px;
}

.privacy_heading_icon {
  height: 50px;
}

.privacy_heading_icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.privacy_heading h4 {
  color: var(--primary-color);
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  position: relative;
}

.privacy_heading h4:before {
  content: "";
  height: 2px;
  width: 160px;
  top: 50%;
  transform: translateY(-50%);
  background: var(--primary-color);
  position: absolute;
  right: calc(100% + 20px);
}

.privacy_heading h4:after {
  content: "";
  height: 2px;
  width: 160px;
  top: 50%;
  transform: translateY(-50%);
  background: var(--primary-color);
  position: absolute;
  left: calc(100% + 20px);
}

.privacy_content p {
  color: rgba(0, 0, 0, 0.7);
  text-align: justify;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.54px;
}

/* Common Header */
.cmn_hdr {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cmn_hdr h3 {
  color: #000;
  font-size: 1.4em;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.cmn_hdr a {
  color: #197bbd;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease 0.1s;
}

.cmn_hdr a:hover {
  /* transform: translateX(-10px); */
}

/* Upcoming Task List */
.task_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.task_list_item h5 {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 10px;
  background: #fafafa;
  padding: 14px 16px;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 700;
}

.task_list_item h5 svg {
  width: 22px;
  height: 22px;
}

/* Input Fields */
.input_field_Item:not(:last-child) {
  margin-bottom: 40px;
}

.input_label {
  color: var(--secondry-color);
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5em;
}

.input_field input {
  border-radius: 35px;
  border: 2px solid var(--secondry-color);
  background: #fdfdfd;
  padding: 14px 20px;
  width: 100%;
  font-size: 18px;
  line-height: 1.2em;
}

.input_field.icon {
  position: relative;
  display: flex;
}

.input_field.icon img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  cursor: pointer;
}

.input_field.icon input {
  padding-right: 50px;
}

/* Login css */
.lgn_btn_wpr {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-top: 50px;
}

.lgn_btn_wpr button {
  border-radius: 35px;
  border: 1px solid var(--pink-color);
  background: var(--pink-color);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  padding: 16px 24px;
  cursor: pointer;
}

.lgn_btn_wpr button:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.lgn_btn_wpr a {
  color: var(--secondry-color);
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.lgn_btn_wpr a:hover {
  color: var(--primary-color);
}

/* Form Css */

.todo_form {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
}

.todo_form.item2 .input_wpr {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% / 2 - 20px);
}

.todo_form .input_wpr label {
  color: var(--secondry-color);
  font-size: 16px;
  font-weight: 700;
  line-height: 2.5;
}

.form_row {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 40px;
  margin-top: 40px;
}

.input_wpr {
  flex: 0 0 100%;
}

.form_row .input_wpr {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
}

.form_row.item2 .input_wpr {
  flex: 0 0 calc(100% / 2 - 20px);
}

.input_wpr.w-48 {
  max-width: calc(100% / 2 - 20px);
}

.form_row .input_wpr label {
  color: var(--secondry-color);
  font-size: 16px;
  font-weight: 700;
}

.full {
  width: 100%;
}

.form_row.item2 .full .input_wpr {
  width: 100%;
  flex: 0 0 100%;
}

.full .input_wpr input[type="text"] {
  padding: 12px 15px;
  border: 0;
  background: #f4f4f4;
}

.full .input_wpr input[type="text"]::placeholder {
  color: #000 !important;
}

.input_wpr input {
  border-radius: 24px;
  border: 1px solid #9e9e9e;
  background: #fdfdfd;
  padding: 14px 20px;
  width: 100%;
  font-size: 14px;
  color: #000;
}

.input_wpr.text_area {
  flex: 0 0 100% !important;
}

.input_wpr.text_area textarea {
  border-radius: 24px;
  border: 1px solid #9e9e9e;
  background: #fdfdfd;
  padding: 14px 20px;
  width: 100%;
  font-size: 14px;
  color: #000;
  min-height: 100px;
  resize: none;
}

input:-internal-autofill-selected {
  background-color: #0000 !important;
}

.form_row.item2 .input_wpr input::placeholder {
  color: #949494;
}

.input_wpr.select__bx fieldset {
  border-radius: 30px;
  border-color: #9e9e9e;
}

.input_wpr.select__bx > div > div {
  padding-top: 12px;
  padding-bottom: 13px;
  font-size: 14px;
  padding-left: 20px;
  /* color: #b2b4b2; */
  color: #4d4747;
}

.input_wpr.select__bx fieldset:focus,
.input_wpr.text_area textarea:focus,
.form_row.item2 .input_wpr input:focus {
  border-color: var(--secondry-color);
}

.input_wpr div,
.create_form .input_wpr div.error_text {
  font-size: 12px;
  color: #c32b2b;
  font-weight: 700;
}

.input_wpr select {
  border-radius: 24px;
  border: 1px solid #9e9e9e;
  background: #fdfdfd;
  padding: 14px 20px;
  width: 100%;
  font-size: 14px;
  color: #000;
}

.input_wpr.bg-select select {
  border-radius: 24px;
  color: #9e9e9e;
  background: #f5f5f5;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
}

.input_wpr select option {
  color: #000;
}

.form-control {
  padding: 15px 20px;
  background: #f9f9f9;
  border-radius: 16px;
  font-size: 14px;
  border-color: #0000;
  text-transform: capitalize;
  border: 1px solid #949494;
  margin-bottom: 10px;
}

.input_box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input_box label {
  color: var(--secondry-color);
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 8px;
}

.new_form .form-group {
  /* width: calc(50% - 20px);
  /* width: 10%; */
  /* margin-bottom: 10px; */
  display: flex;
  flex-wrap: wrap;
}

.new_form {
  display: flex;
  flex-wrap: wrap;
  /* row-gap: 20px; */
  column-gap: 40px;
}

.unstyled-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.unstyled-button img {
  display: block;
}

/* Simple Tabbing */
.smpl_tab_btns {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.smpl_tab_btns li {
  border-radius: 27.5px;
  border: 1px solid #fff;
  background: #fff;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  min-width: 110px;
  text-align: center;
  text-transform: capitalize;
}

.smpl_tab_btns li.active,
.smpl_tab_btns li:hover {
  background-color: var(--secondry-color);
  border-color: var(--secondry-color);
  color: #fff;
}

.smpl_tab_ctnt {
  opacity: 0;
  animation: fade 0.5s ease forwards;
}

.smpl_tab_ctnt.active {
  /* display: block; */
  animation: fade 0.5s ease forwards;
}

.extra_doc ul li button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.extra_doc ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.extra_doc {
  margin: 20px 0px;
}

.extra_doc ul li img {
  width: 20px;
  margin-right: 10px;
}

.line {
  border-left: 2px solid #85888b; /* idth as needed */
  height: 20px; /* Adjust the height as needed */
  margin: 0 10px; /* Adjust the margin as needed */
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Pie chart  */
.piechart_wpr_out {
  filter: drop-shadow(0px 10px 35px rgba(0, 0, 0, 0.03));
  background: #fff;
  border-radius: 20px;
  min-height: 260px;
  padding: 20px 15px;
}

.piechart_wpr {
  display: flex;
  align-items: center;
  justify-content: center;
}

.piechart_wpr_out h5 {
  color: #243465;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3em;
  letter-spacing: 0.277px;
}

/* Date */
.date_wpr {
  position: relative;
  border-radius: 29.5px;
  background: #fff;
  padding: 10px 20px;
  color: #666666;
}

.date_wpr input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.date_wpr input[type="date"]::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.date_inr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

/* Create Todo List */
.create_todo_wpr {
  /* display: flex; */
}

.create_todo_wpr .MuiSkeleton-root {
  height: 46vh !important;
}

.create_todo_wpr > div {
  /* flex: 0 0 calc(100% / 2); */
  /* max-width: 50%; */
}

.class-select {
  color: #197bbd;
  line-height: 38px;
}

.create_todo_rgt {
  display: flex;
  justify-content: center;
  padding: 50px;
}

.create_todo_rgt.no-pdf {
  position: relative;
  filter: blur(1px);
}

.create_todo_rgt.no-pdf:after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: #0101014d;
}

.create_form {
  padding-right: 35px;
}

.create_form .form_row .input_wpr.select__bx > div {
  border-radius: 30px;
  background-color: #f4f4f4;
}

.create_form .input_wpr div {
  font-weight: 400 !important;
  color: #000 !important;
}

.create_form .form_row .input_wpr.select__bx fieldset {
  border: 0;
}

.create_form .form_row > .date_err_wpr {
  flex: 0 0 calc(100% / 2 - 20px);
}

.create_form .form_row > .date_err_wpr .date_wpr {
  background-color: #f4f4f4;
  color: #000;
  font-size: 14px;
  padding: 14px 20px;
}

.create_form .form_row textarea {
  background-color: #f4f4f4;
  color: #000;
  border: 0;
  height: 220px;
}

.create_todo_lft {
  max-height: 76vh;
  overflow-y: scroll;
}

.create_todo_lft::-webkit-scrollbar {
  padding: 0;
  display: none;
}

.create_todo_lft .multi_Select {
  flex: 0 0 calc(100% / 2 - 20px);
}

.create_todo_lft .multi_Select > div > div {
  padding: 15px 20px;
  background: #f9f9f9;
  border-radius: 16px;
  font-size: 14px;
  border: 1px solid #949494;
}

.create_todo_lft .multi_Select fieldset {
  border: 0 !important;
}

.voice_gen {
  display: flex;
  gap: 10px;
  width: 100%;
  cursor: pointer;
}
.tab_sec {
  padding: 25px 0px;
}

.sub_sec {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 40px;
  margin-top: 40px;
}

.plan_gen {
  display: flex;
  padding: 15px 20px;
  border-radius: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  background: #fff;
  box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.175);
  flex-basis: calc(100% / 2 - 20px);
}

.plan_gen:hover {
  background-color: rgba(0, 122, 255, 0.15);
}

.plan_gen img {
  padding-left: 11px;
  padding-right: 11px;
}

.hdr_content {
  font-size: 18px;
  font-weight: 800;
  line-height: 2.5;
}
.hdr_content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.common-button {
  margin-top: 10px;
  width: 100%;
  background-color: #e75a7c;
  border-radius: 20px;
  color: #fff;
  height: 50px;
  font-size: 18px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

/* Recent List */
.recent_list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.recent_list > li {
  border-radius: 12px;
  border: 1px solid #197bbd;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
  width: 100%;
  display: flex;
  padding: 10px 15px;
  gap: 10px;
  font-size: 14px;
  align-items: center;
  color: var(--primary-color);
  font-weight: 700;
}

.recent_list > li ul {
  margin-left: auto;
}

.icon {
  line-height: 1;
}

.icon img {
  width: 18px;
  height: 18px;
  object-fit: contain;
  line-height: 1;
}

/* Chat Wpr */
.chat_wpr {
  padding: 40px 20px 20px;
  background: #f8f8f8;
  border-radius: 20px 15px 15px 20px;
  position: relative;
  width: 100%;
}

.create_todo_rgt .chat_wpr {
  padding: 20px;
}

.cht_prop {
  display: flex;
  gap: 12px;
  flex-direction: column;
  list-style-type: none;
  counter-reset: list-counter;
  padding-left: 1rem;
  height: 50vh;
  overflow-y: auto;
}

.cht_prop_itm {
  padding: 10px 15px;
  border-radius: 12px;
  background: #fff;
  pointer-events: none;
  cursor: not-allowed;
  counter-increment: list-counter;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  position: relative;
}
.cht_prop_itm:before {
  content: counter(list-counter) ".";
  font-weight: bold;
  position: absolute;
  left: -2rem;
  font-size: 10px;
  width: 20px;
  height: 20px;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.cht_prop_itm.enable {
  pointer-events: all;
}

.cht_prop_itm.disabled,
.cht_prop_itm.disabled > * {
  cursor: no-drop;
  opacity: 0.8;
  background-color: #f1f1f1;
}

.cht_prop_itm h6 {
  font-size: 12px;
  cursor: pointer;
}

.cht_prop_itm p {
  font-size: 12px;
  color: #9e9e9e;
  cursor: pointer;
}

.orRubric {
  position: absolute;
  left: 50%;
  bottom: -1.6rem;
  transform: translateX(-50%);
  font-size: 0.8rem;
  font-weight: 500;
  color: #aaa;
}

.send_msg {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 14px;
}

.send_msg textarea {
  width: 100%;
  margin: 0 auto;
  border: 0;
  padding: 15px 45px 15px 15px;
  border-radius: 14px;
  resize: none;
  height: 80px;
}

.send_msg textarea::-webkit-scrollbar {
  padding: 0;
  display: none;
}

.send_msg img {
  position: absolute;
  right: 30px;
  bottom: 40px;
  cursor: pointer;
}

.chat_itm_cvr {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 12px;
  max-height: 46vh;
  overflow: scroll;
  word-break: break-word;
}

.chat_itm_cvr h5 {
  font-size: 14px;
  margin-bottom: 5px;
}

.chat_itm_cvr p,
.chat_itm_cvr li {
  font-size: 14px;
  color: #9e9e9e;
  margin-bottom: 10px;
}

.reg_btns {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-top: 20px;
}

.reg_btns li {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #000;
  cursor: pointer;
}

.reg_btns li img {
  width: 14px;
  height: 14px;
}

/* Loader */
.ldr_wpr {
  padding: 40px 40%;
}

.error_msg {
  font-size: 12px;
  color: #c32b2b;
  font-weight: 700;
  margin-top: 5px;
}

.err_msg {
  font-size: 12px;
  color: #c32b2b;
  font-weight: 700;
  margin-bottom: 20px;
  /* margin-top: 5px; */
}
.save_btn {
  position: relative;
}

.save_btn button {
  position: absolute;
  bottom: calc(100% + 30px);
  right: 0;
}

.save_btn_spc {
  padding-right: 100px;
}

.search_wp input[type="date"]::before {
  width: 100%;
  content: attr(placeholder);
  position: absolute;
  background: #f4f4f4;
}

.search_wp input {
  background: #f4f4f4;
  border: 0;
  padding: 15px 20px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid #949494;
}
.search_wp {
  flex: 1 0 300px;
}

.search_wp span {
  display: inline-block;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.search_wp input[type="date"].opacity-zero::before {
  opacity: 0;
}

select.select_inpt {
  padding: 15px 20px;
  background: #f9f9f9;
  border-radius: 16px;
  font-size: 14px;
  border-color: #0000;
  text-transform: capitalize;
  border: 1px solid #949494;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

.input_task input {
  background-color: #f4f4f4;
  color: #000;
  padding: 12px 15px;
  border-color: #0000;
}

.voice_Skel {
  height: 220px !important;
  transform: scaleY(1) !important;
  border-radius: 24px !important;
}

.scrl_inr {
  overflow-y: scroll;
  padding-bottom: 100px;
}

.scrl_inr ::-webkit-scrollbar {
  display: none;
}

/* .show_data {
  margin: 10px 0px;
} */
/* Hide scrollbar for IE, Edge and Firefox */
.scrl_inr {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.create_todo_rgt .chat_resp_itm > * {
  word-break: initial;
}

.create_todo_rgt .chat_resp_itm tr td:first-child,
.create_todo_rgt .chat_resp_itm tr th {
  white-space: nowrap;
}

.table_wpr iframe {
  width: 100%;
  height: 65vh;
}

.dwn-pdf {
  display: inline-flex;
}

.crt_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
  gap: 20px;
  flex-direction: column;
}

.disable_form {
  opacity: 0.5;
  background: #bababa;
  padding: 15px;
  border-radius: 10px;
}

.btn_group {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.rework_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: #ff9900;
  color: #fff;
  padding: 8px 15px 8px 16px;
  border-radius: 20px;
  font-size: 12px;
  gap: 14px;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0.1s;
}

.approve_btn {
  background-color: #3a7d44;
}

.rework_btn:hover {
  opacity: 0.8;
}

.text_inpt_wpr {
  display: flex;
  margin-top: 15px;
  align-items: center;
  gap: 14px;
}

.text_inpt_wpr * {
  padding: 8px 20px;
  font-size: 14px;
}

input[name="tasktitle"],
textarea[name="description"],
textarea[name="description"]::placeholder,
input[name="tasktitle"]::placeholder {
  color: #000 !important;
}

input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}

.border-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.side-border {
  flex: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.left-border {
  margin-right: 10px;
}

.right-border {
  margin-left: 10px;
}

.center-text {
  text-align: center;
  white-space: nowrap; /* Prevent text wrapping */
}

.file_uploading {
  display: flex;
}
.upload-left {
  width: 50%;
  margin-right: 50px;
}
.upload-right {
  width: 50%;
}

.sep_doc {
  margin: 10px 0px;
}

.sep_doc ul li button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sep_doc ul {
  display: flex;
  justify-content: flex-start;
}

.sep_doc ul li img {
  width: 20px;
  margin-right: 10px;
}

@media screen and (max-width: 1500px) {
  .plan_gen img {
    width: 89px;
  }

  .hdr_content {
    font-size: 15px;
    white-space: nowrap;
  }
}
