.viewprofile_sec {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 31px 0px rgba(44, 50, 63, 0.02);
}

.vp_cover_img {
    height: 200px;
}

.vp_cover_img .prft_img {
    border-radius: 15px;
    background-color: #e4e4e4;
    box-shadow: 0px 0px 31px 0px rgba(44, 50, 63, 0.02);
}

.vp_person_bx {
    display: flex;
    gap: 35px;
    padding: 0 70px;
    margin-top: -60px;
}

.vp_person_img {
    flex: 0 0 180px;
    max-width: 180px;
    width: 100%;
    height: 180px;
    background: #f6f6f6;
    border-radius: 50%;
    border: 1px solid var(--pink-color)
}

.vp_person_img img {
    border-radius: 50%;
}

.vp_person_wpr {
    flex-grow: 1;
    display: flex;
    padding-top: 80px;
    justify-content: space-between;
}

.vp_person_info h4 {
    font-size: 1.5em;
    line-height: 1.5em;
}

.vp_person_info p {
    color: #666;
    font-size: 16px;
}

.vp_basic_info {
    padding: 50px 70px;
    display: flex;
    /* gap: 30px; */
    justify-content: flex-start;
    flex-wrap: wrap;
}
.vp_basic_info li {
    position: relative;
    flex: 0 0 calc(100% / 6) !important;
    padding: 0 10px;
    width: 100% !important;
}

.vp_basic_info li:after {
    position: absolute;
    content: "";
    right: 0px;
    top: 0;
    width: 1px;
    height: 100%;
    background: #000000a8;
}

.vp_basic_info li:nth-child(2){
    max-width: 320px !important;
    flex-grow: 1 !important;
}
.vp_basic_info li:nth-child(4) {
    max-width: 180px;
    flex-grow: 1;
}
.vp_basic_info li:nth-last-child(2), .vp_basic_info li:last-child, .vp_basic_info li:nth-child(3) {
    flex: 0;
    min-width: 120px;
}
.vp_basic_info li b {
    white-space: nowrap;
}
.vp_basic_info li p {
    font-size: 13px;
    word-break: break-all;
}


.vp_basic_info li:last-child:after {
    display: none;
}